import { COUNTRY_LIST } from "./country";

export const LOCALSTORAGE = {
    translations: "translations",
    lang: "lang"
}

export const SESSSION_OUT = (props) =>{
    
    props._post(LOGOUT,"",(res)=>{
        console.log(res);
        SESSION_CLEAR();
        
    },(err)=>{
        console.log(err);
        SESSION_CLEAR();
    })
}

export const SESSION_CLEAR = ()=>{
    const lang = sessionStorage.getItem("lang");
    const translations = sessionStorage.getItem("translations");

    window.sessionStorage.clear();

    window.sessionStorage.setItem("lang", (lang)?lang:"en");
    window.sessionStorage.setItem("translations", translations);
    window.location.href="/login";
}

export const EMAIL_API_KEY = "602445c64560b";

export const GOOGLE_API_KEY = "AIzaSyAsUP6PEYUY8LOUQD4xmFTyE4Zd1cbFjkE";

export const DEFAULT_USER_IMG_PATH = "https://regofilesstage.blob.core.windows.net/avatars/default-avatar.png";

export const YT_THUMB_URL =(mediaId)=> `https://img.youtube.com/vi/${mediaId}/mqdefault.jpg`;
export const VIMEO_THUMB_URL = "https://vumbnail.com/253989945_large.jpg";

export const FALLBACK_LANG = "en";

export const LANG = window.sessionStorage.getItem("lang") || FALLBACK_LANG;

// Request Methods
export const GET = "GET"
export const POST = "POST"
export const PATCH = "PATCH"
export const PUT = "PUT"
export const DELETE = "DELETE"

export const ENTITY = {
    MAZOOLA: "MAZOOLA",
    REGO: "REGO",
    CHORE_CHECK: "Chore Check"
}

export const ENTITY_BRAND = {
    MAZOOLA: "Mazoola",
    REGO: "REGO",
    CHORE_CHECK: "Chore Check"
}

// Headers
export const HEADERS = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

export const HEADERS_UPLOAD = {
    'Content-Type': 'multipart/form-data',
};

export const countries = COUNTRY_LIST;

export const VIEW_CONTENT = "View Content";

// API
export const LOGIN = "token";
export const CONFIG = "config";
export const LOGINV5 = "v5/token";
export const VERIFYOTP = "v5/tokens/verify-one-time-code";
export const RESENDOTP = "v5/token/resend-code";
export const REGISTER = "token";
export const AUTH = "auth";
export const USERS = "user";
export const USERS_UPDATE = (userId) => `user/${userId}`;
export const FORGOT = "users/forgot";
export const ADMINPERMISSIONS = "admin/permissions";
export const DASHBOARD = "dashboard.json";
export const CHILDLIST_DASHBOARD = 'v5/accounts'
export const HISTORY = "history";
export const NOTIFICAIONS = "notifications";
export const RESET = "user/password-reset";
export const SETPWD = "user/set-password";
export const TOKEN_PWD = "token/password-reset";
export const CONTACT = "contact";
export const ADD_PEER = "contacts/inviteandtransfer";
export const DEL_PEER = (childId) => `user/${childId}`;
export const ADD_CHILD = (guid) => `restServices/ui/v01-childmemberships/v01-childmembership/${guid}/v01-childmembership-AddChildLoginCredentials`;
export const ADD_CHILD_S1 = `restServices/ui/v01-sponsormemberships/v01-sponsormembership/v01-membership-RegisterChild`;
export const ADD_CHILD_S2 = (guid) => `restServices/ui/v01-childmemberships/v01-childmembership/${guid}/v01-RegisterChildPhone`;
export const ADD_CHILD_S3 = (guid) => `restServices/ui/v01-childmemberships/v01-childmembership/${guid}/v01-childmembership-AddChildLoginCredentials`;
//export const ADD_CHILD_S1 ="restServices/ui/v01-sponsormemberships/v01-sponsormembership/v01-membership-RegisterChild";
export const FUNDING_SOURCE_LINK = `v6/fundingsource/createFundingSourceByLink`;
export const FUNDING_SOURCE_CARD = (childUserId) => `cards/details/${childUserId}`;
export const FUNDING_SOURCE = `v6/funding-sources`;
export const FUNDING_SOURCE_DEL = (id) => `v5/funding-sources/${id}`;
export const FUNDING_SOURCE_VERIFY = (id) => `fundingsource/${id}/verify`;
export const FUNDING_TERMS = `fundingsource/terms`;
export const CHORES = "v6/chores/guardian";
export const CHORES_COMMON = "v5/chores-common";
export const CHORES_ADD = `v6/chores`;
export const CHORES_GET = (childId) => `v6/chores/guardian/${childId}`;
export const CHORES_UPDATE = (choreId) => `v6/chores/${choreId}`;
export const CHORES_PATCH = (choreId, wardId) => `v5/chores/${choreId}/${wardId}`;
export const CHORES_PATCH_INSTID = (choreInstanceId) => `v6/chores/${choreInstanceId}`;
export const CHORES_DELETE = (choreId) => `v5/chores/${choreId}`;
export const CHORES_CHILD = (childId) => `v6/chores/ward/${childId}`;
export const GOALS = "v5/accounts";
export const GOAL_LIST = "v5/accounts";
export const GOALS_BY_ACCOUNT = (accountId) => `v5/accounts/${accountId}`;
export const TENANT_NAME = (tenantName, language) => `tenant/${tenantName}/${language}`;
export const SSO = (userName, password) => `/generate-token?userName=${userName}&userPassword=${password}`;
export const CONTENTS = `v5/contents`;
export const CONTENTS_ADD = `v6/contents`;
export const CONTENTS_GET = (contentId) => `v5/contents/${contentId}`;
export const CONTENTS_UPDATE = (contentId) => `v6/contents/${contentId}`;
export const CONTENTS_ASSIGN = (contentId) => `v5/contents/${contentId}/share`;
export const CONTENTS_UNASSIGN = (contentId) => `v5/contents/${contentId}/unshare`;
export const CONTENT_DELETE = (contentId) => `v5/contents/${contentId}`;
export const WALLET_HISTORY = "v5/history";
export const WALLET_HISTORY_V6 = "v6/history";
export const WALLET_HISTORY_V6_CHILD = (childId) => `v6/history/${childId}`;
export const V5USERS = "v5/user";
export const V6USERS = "v6/user";
export const RELATIONSHIPS = (id) => `v5/wards/${id}/guardians`;
export const RELATIONSHIPS_DEL = (id) => `v5/wards/${id}/guardians`;
export const RELATIONSHIPS_DEL_GID = (id,gid) => `v5/wards/${id}/guardians/${gid}`;
export const V5USERS_Id = (childId) => `user/${childId}`;
export const V5USERS_Details = (childId) => `v5/user/${childId}/details`;
export const V5ADDRESSES = "v5/addresses";
export const V5ADDRESSES_CHILD = (childId) => `v5/addresses/${childId}`;
export const V5ADDRESSES_UPDATE = (childId, addressId) => `v5/addresses/${childId}/${addressId}`;
export const V5ADDRESSES_UPDATE_ = (addressId) => `v5/addresses/${addressId}`;
export const V5ADDRESSES_DELETE_ = (addressId) => `v5/addresses/${addressId}`;
export const CONTACT_INVITETRANSFER = "contacts/inviteandtransfer";
export const CONTACT_INVITETRANSFER_DEL = (tId) => `contacts/inviteandtransfer/${tId}`;
export const USER_PIN = 'v5/users/pin';
export const CHANGEPASSWORD = "user/password";
export const CONTACTSV5 = 'v5/contacts';
export const CONTACTSPENDING = `v5/contacts/invite`;
export const CONTACTSAPPROVEV5 = (id) => `v5/contacts/invite/${id}/approve`;
export const CONTACTSREJECTV5 = (id) => `v5/contacts/invite/${id}/reject`;
export const CONTACTSREMOVE = (id) => `v5/contacts/invite/${id}`;
export const CONTACTSV6 = 'v6/contacts';
export const CONTACTSV5_DEL = (contactId) => `v5/contacts/${contactId}`;
export const CHORE_RECURRING = `v5/scheduler/chores`;
export const FUNDING_SOURCES = `v5/funding-sources`;
export const FUNDING_SOURCES_CHILD = (wardId) => `cards/details/${wardId}`;
// export const SCHEDULER_TRANSFER = `v5/scheduler/transfers`;
export const SCHEDULER_TRANSFER = `v6/accounts/scheduled-transfer`;
export const PENDING_TXN = `v6/history/pending`;
export const SCHEDULER_TRANSFER_CHILDID = `v5/scheduler/transfers`;
export const SCHEDULER_TRANSFER_POST = `v5/accounts/scheduled-transfer`;
export const SCHEDULER_TRANSFER_PUT = (scheduledTransferId) => `v6/accounts/scheduled-transfer/${scheduledTransferId}`;
export const CHILD_DELETE = (childId) => `user/${childId}`;

export const ACCOUNT_TRANSFER_POST = `v5/accounts/transfer`;
export const ADD_FUNDS_POST = `v5/accounts/add-funds`;

export const CONTRACT = `contractmodels`;
export const STATEMENTS = `compliance/statements`;
export const RECEIPTS = `compliance/receipts`;
export const RECEIPT_TEMPLATE = (receiptId) => `compliance/receipts/${receiptId}`;
export const PHYSICAL_CARDS = `v5/physical-cards`;
export const PHYSICAL_CARDS_ORDER = `v5/physical-cards/order`;
export const PHYSICAL_CARDS_ORDER_DELETE = (wardId) => `v5/physical-cards/order/cancel/${wardId}`;
export const PHYSICAL_CARDS_ORDER_REISSUE = (wardId) => `v5/physical-cards/reissue/${wardId}`;
export const PHYSICAL_CARDS_ORDER_ACTIVATE_SEND = (wardId) => `v5/physical-cards/activate/${wardId}`;
export const PHYSICAL_CARDS_ORDER_ACTIVATE = (wardId, otp) => `v5/physical-cards/activate/${wardId}/${otp}`;
export const CHILD_GLOBAL_SETTING = `v5/user-settings/ward-global-settings`;
export const GLOBAL_SETTING = `v6/user-settings/notification-settings`;
export const GLOBAL_SETTING_UPDATE = `v5/user-settings/notification-settings`;
export const CHILD_SPENDING_SETTING_V6 = (wardId) => `v6/user-settings/ward-spending-settings/${wardId}`;
export const CHILD_CHARITY_SETTING = `v5/charities/activated`;
export const CHILD_SPENDING_SETTING = (wardId) => `v5/user-settings/spending-targets/${wardId}`;
export const VIEW_CARD = (wardId, allowShowCard) => `v5/users/authorize-ward/${wardId}/${allowShowCard}`;
export const APPROVED_RETAILERS = `v5/merchants/configured`;
export const APPROVED_RETAILERS_WARD = `v5/merchants/configured/ward`;
export const UNAPPROVED_RETAILERS = `v5/merchants/not-configured`;
export const SHOPPING_CHARITIES = `v5/charities`;
export const SEARCH_SHOPPING_CHARITIES = (searchItem,page) => `v5/charities/search/${searchItem}?pageSize=10&page=${page}`;
export const CHARITY_ID = (charityId) => `v5/charities/${charityId}`;
export const SHOPPING_RETAILERS_SEARCH = (searchItem) => `shopping/search/Walmart?searchTerms=${searchItem}`;
export const SAVE_RETAILERSETTING_POST = `v5/user-settings/ward-merchant-settings`;
export const GET_RETAILER_SETTING = (merchantId) => `v5/user-settings/ward-merchant-settings?merchantId=${merchantId}`;
export const GET_RETAILER_DEFAULT_LIMIT = `v5/user-settings/ward-global-settings`;
export const GET_RELATIONSHIP = "v5/accounts";
export const SCHEDULES_TRANSFERS = `v5/accounts/scheduled-transfer`;
export const SAVE_CharitySetting_POST = `v5/user-settings/ward-charity-settings`;
export const CHARITY_LIMIT = (charityId, childId) => `v5/charities/${charityId}/users/${childId}/limits`;
export const SAVE_Charity_POST = (charityId, childId) => `v5/charities/${charityId}/users/${childId}/limits`;
export const RETAIER_DELETE = (childId, retailerId) => `v5/user-settings/ward-merchant-settings/${childId}/${retailerId}`;
export const SAVE_CharitySetting_PUT = (charityId) => `v5/user-settings/ward-charity-settings/${charityId}`;
export const REMOVE_CHARITY = `v5/charities`;
export const FEATURE_FLAG = 'featuretoggle';
export const FEATURE_FLAG_V2 = 'v2/feature-toggles';
export const FEATURE_FLAG_V4 = 'v4/feature-toggles';
export const WARDDISABLEDFEATURES = (wardId) =>  `featuretoggles/features/ward/${wardId}`;
export const MERCHANT_CATEGORIES = 'v5/merchants/categories';
export const ASSIGNCHILD_TO_CHARITY = (charityId, childId) =>  `v5/charities/${charityId}/users/${childId}`;
export const TOGGLECHARITYCHILD = (charityId , childId, state) => `v5/charities/${charityId}/users/${childId}/toggle/${state}`;
//export const CHORE_RECURRING = `v5/scheduler/chores`;
export const USER_SPENDING = (wardId) => `v5/user-settings/spending-targets/${wardId}`;
export const INVITE_WARD = `v5/wards/invite`;
export const INVITE_USER = `v5/users/invite`;
export const INVITE_GUARDIAN = `v5/guardians/invites`;
export const INVITE_SENIOR = `v6/guardians/invites`;
export const INVITE_WARD_CHILD = (wardId) => `v5/wards/invite/${wardId}`;
export const WARDS_SUMMARY = `v8/wards/summary`;
export const MILESTONES_COUNT = `v5/users/milestones/remaining/count`;
export const REGISTER_SPONSER = `restServices/ui/v01-sponsormemberships/Tasks/RegisterSponsor`;
export const REGISTER_SPONSER_V3 = `v4/registrations/register-sponsor`;
export const VERIFY_PHONE = `restServices/ui/v01-sponsormemberships/v01-sponsormembership/v01-sponsormembership-VerifyPhone`;
export const VERIFY_PHONE_V3 = `v3/registrations/verifyPhone`;
export const ACTIVATE_PHONE = `restServices/ui/v01-sponsormemberships/v01-sponsormembership/v01-sponsormembership-ActivatePhone`;
export const ACTIVATE_PHONE_V3 = `v3/registrations/activatePhone`;
export const VERIFY_IDENTITY = `v3/restServices/VerifyIdentity`;
export const VERIFY_IDENTITY_V3 = `v3/registrations/verifyIdentity`;
export const REGISTER_STATUS = `v5/guardians/registration/status`;
export const REGISTER_STATUS_V3 = `v3/registrations/registrationStatus`;
export const CHANGE_PWD = `v5/user/change-password`;
export const CHANGE_PWD_PAR = `user/password`;
export const PARENT_CHANGE_CHILDPASS = (childId) => `user/${childId}/password`;
export const GUARDIAN_ROLE = (guardian) => `v5/guardians/${guardian}/wards`;
export const WARD_ROLE = (wardId) => `v5/wards/${wardId}/guardians`;
export const CHARITY_APPROVED = `v5/charities/activated`;
export const MILESTONES = `v5/users/milestones`;
export const DELETE_SCHEDULED_T = (scheduledTransferId) => `v5/accounts/scheduled-transfer/${scheduledTransferId}`;
export const SIGNAL_R_TOKEN = `signals`
export const INVESTMENTS_CREATE_POST = `investments/accounts`;
export const INVESTMENTS_CREATE = `v2/investments/accounts`;
export const INVESTMENT_BALANCE = (wardId) => `v2/investments/accounts?wardId=${wardId}`;
export const INVESTMENTS_GET = `investments/known-customer-info`
export const INVESTMENTS_SETTINGS = `investments/settings`
export const INVESTMENTS_SETTINGS_POST = `v2/investments/settings`;
export const INVESTMENTS_ACCOUNTCHILD = `investments/accounts-child`
export const INVESTMENTS_ASSETS = `investments/assets`
export const INVESTMENTS_WATCHLIST = `investments/watchlist`
export const INVESTMENTS_ADD_WATCHLIST = (symbol) => `investments/watchlist/${symbol}`
export const INVESTMENTS_DELETE_WATCHLIST = (symbol) => `investments/watchlist/${symbol}`
export const INVESTMENTS_ASSET_HISTORY = (symbol) => `investments/assets/${symbol}/history`
export const INVESTMENTS_ASSET_LOGO = (symbol) => `investments/assets/${symbol}/logo`;
export const INVESTMENTS_POSITIONS = `investments/positions/history`;
export const INVESTMENTS_PORTFOLIO = (period, endDate, brokerHistoryFrame, extendedHours, isPractice, wardId) => `/investments/positions/history?period=${period}&endDate=${endDate}&brokerHistoryFrame=${brokerHistoryFrame}&extendHours=${extendedHours}&isPractice=${isPractice}&wardId=${wardId}`;
export const INVESTMENTS_POS = `investments/positions`;
export const INVESTMENTS_ORDER = `investments/orders`;
export const INVESTMENTS_ORDER_CANCEL = (orderId) => `investments/orders/${orderId}`;
export const INVESTMENTS_ACTIVITIES = `investments/activities`;
export const INVESTMENTS_STMT = `investments/statements`;
export const INVESTMENTS_RECIEPTS = `investments/trade-confirmations`;
export const CHANGE_GUARDIANROLE = (guardianId) => `v5/guardians/${guardianId}/wards`;
export const DELETE_ADULT_RELATIONSHIP = (guardianId) => `v5/guardians/relationships/${guardianId}`;
export const TRUSTED_CONTACT = `v6/guardians/trusted-contacts`;
export const DISPUTE_REASONS = `compliance/disputes/reasons`;
export const DISPUTE_CREATE = `compliance/disputes`;
export const DISPUTE_STATUS = (disputeId) => `compliance/disputes/${disputeId}`;
// export const GET_CHILD_MERCHANT_SETTINGS = (childId, merchantId) => `v5/user-settings/child-merchant-settings?childId=${childId}&merchantId=${merchantId}`
export const GET_CHILD_MERCHANT_SETTINGS = `v5/merchants/configured/ward`;
export const ON_DEMANDSIGNALR = `signals/on-demand`;
export const MARKVIEWED_ONDEMAND = (notificationId) => `signals/mark-as-viewed/${notificationId}`;
export const USER_EXIST = (username) => `v6/users/exists?username=${username}`;
export const INVESTMENT_LIMITS = `investments/limits`;
export const INVESTMENT_COMBDAILY_LIMITS = `investments/limits/used`;
export const AVAIL_AVATARS = `v5/config/available-avatar`;

//child
export const ACTIVATE_PIN = (invitePin) => `v5/token/ward-invite-pin/${invitePin}`;
export const CREATE_WARD_CRED = `v5/wards/credentials`;
export const CHILD_ACCOUNTS = `v6/accounts`;
export const CHILD_CARD_DETAILS = `cards/details`;
export const CHILD_CHORES = `v6/chores/ward`;
export const CHILD_SPENDING_TARGETS_90 = (childId) => `v5/user-settings/spending-targets/90?childId=${childId}`
export const PARENT_SPENDING_TARGETS_DAYS = (days, childId) => `v5/user-settings/spending-targets/${days}?childId=${childId}`
export const PAY_PEER = `contact/transfer`;
export const CHARITY_DONATE = `v5/charities/donate`;
export const PAYMENT_VALIDATE = `payment/validate`;
export const INVITE_PEER =(userName)=> `/v5/token/resend-code?userName=${userName}`;
export const REQUEST_MONEY = `notifications/request-money`;
export const NOTIFICATION_SETTINGS_WARD = (wardId) =>  `v7/user-settings/notification-settings/${wardId}`;
export const UPDATE_NOTIFICATION_SETTINGS_WARD = (userId) =>  `v7/user-settings/notification-settings/${userId}`;
export const RESEND_ONBOARDING_PHONEVERIFICATION = (userId) =>  `user/${userId}/resend-phone-verification`;

export const SENIOR_EXTERNAL_ACCOUNTS = `v1/accounts/external`;
export const SENIOR_EXTERNAL_ACCOUNTS_OVERVIEW = `v1/accounts/external/overview`;
export const SENIOR_PROTECTION_SCORE = `v1/protections/score`;
export const SENIOR_PROTECTION_LIST = `v1/protections/list`;
export const SENIOR_CURRENT_PROTECTION_MARK = (protectionId) => `v1/protections/${protectionId}`;
export const SENIOR_CURRENT_PROTECTION_UNMARK = (protectionId) => `v1/protections/${protectionId}`;
export const TXN_RECURRING_BILLS = `v1/transactions/recurring-bills`;
export const TXN_RECURRING_BILLS_OVERVIEW = `v1/transactions/recurring-bills/overview`;
export const TXN_RECURRING_BILLS_YEARLY = `v1/transactions/recurring-bills/yearly`;
export const SENIOR_CURRENT_PROTECTION_MARK_UNMARK = (protectionId) => `v1/protections/${protectionId}`;
export const SENIOR_CREATE_CARD = `v1/cards/create`;
export const SENIOR_BUDGETS = (month, year) => `v1/budgets?month=${month}&year=${year}`;
export const SENIOR_SPENDING_CATEGORIES = (month, year ) => `v1/transactions/categorized-summary?month=${month}&year=${year}`;
export const SENIOR_INVESTMENT_HOLDINGS = (accountGuid) => `v1/accounts/external/${accountGuid}/holdings`;

export const LOGOUT = `Logout`;